import Vue from 'vue'
import {Vuelidate} from "vuelidate";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'materialize-css/dist/js/materialize.min'
import { StripePlugin } from '@vue-stripe/vue-stripe';
import AxiosPlugin from 'vue-axios-cors';

const options = {
  pk: `${process.env.VUE_APP_SECRET_STRIPE_PK}`,
  stripeAccount: 'acct_1LDVKkGLwnfVTJ3h',
  testMode: false,
  sk: `${process.env.VUE_APP_SECRET_STRIPE_SK}`
};

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(AxiosPlugin)
Vue.use(StripePlugin, options);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
