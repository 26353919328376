<template>
  <div class="container container-thx valign-wrapper">
    <div class="row center center-align">
      <h4>Thank You</h4>
      <p>Your appointment is confirmed! Check your email for the details.</p>
      <p class="center">
        <a href="/">Schedule another appointment</a>
      </p>
    </div>
  </div>
</template>

<style scoped>
.container-thx {
  height: 100vh;
}
</style>

<script>

export default {
  name: 'ThanKYou'
}
</script>
<script setup>
</script>
