<template>
  <div class="container form-container">
    <h3 class="center">TCP Onboarding Flow</h3>
    <h6 class="center">Proof of Concept for Acuity Scheduling, Stripe, and Welkin API</h6>
    <div class="row"></div>
    <div class="row">
      <div class="col s9 row-styles-animation z-depth-2">
        <form-main-info v-if="!userInformationDone"/>
        <second-form-date-and-time v-if="userInformationDone && !userDateTimeDone"/>
        <stripe-form v-if="userInformationDone && userDateTimeDone"/>
      </div>

      <div class="col s3">
        <div class="steps-app z-depth-2">
          <div class="step-item" :class="{active: !userInformationDone}">
            <span>Personal Details</span>
          </div>
          <div class="step-item" :class="{active: userInformationDone && !userDateTimeDone}">
            <span>Date and Time</span>
          </div>
          <div class="step-item" :class="{active: userInformationDone && userDateTimeDone}">
            <span>Payment</span>
          </div>
          <div class="step-item">
            <span>Confirmation</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .form-container {
    margin-top: 50px;
    max-width: 860px;
  }

  .steps-app {
    position: relative;
    padding: 15px;
  }

  .steps-app:before {
    position: absolute;
    left: 29px;
    background: #D3D3D3;
    width: 2px;
    bottom: 15px;
    top: 15px;
    content: '';
    z-index: 1;
  }

  .steps-app .step-item {
    position: relative;
    padding-left: 40px;
    margin-bottom: 50px;
    z-index: 5;
  }

  .steps-app .step-item.active span:before {
    background: #297194;;
  }

  .steps-app .step-item:nth-last-child(1) {
    margin-bottom: 0;
  }

  .steps-app .step-item span:before {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #D3D3D3;
    display: block;
    content: '';
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    background-color: #D3D3D3;
    z-index: 3;
  }
</style>

<style>
.btn, .btn-large, .btn-small {
  background-color: #297194;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #256194;
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-color: #297194;
  box-shadow: 0 1px 0 0 #297194;
}

input:not([type]):focus:not([readonly])+label,
input[type=text]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label {
  color: #297194;
}

.datepicker-date-display {
  background-color: #297194;
}
</style>

<script>
import FormMainInfo from "@/components/FormMainInfo.vue";
import SecondFormDateAndTime from "@/components/SecondFormDateAndTime.vue";
import StripeForm from "@/components/StripeForm.vue";

export default {
  name: 'HomeView',
  components: {StripeForm, SecondFormDateAndTime, FormMainInfo},
  computed: {
    userInformationDone () {
      return this.$store.getters.userInformationDone
    },
    userDateTimeDone() {
      return this.$store.getters.userDateTimeDone
    }
  }
}
</script>
