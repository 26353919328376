export default {
    state: {
        userMainInformation: null,
        userInformationDone: false,
        userDateTime: null,
        userDateTimeDone: false
    },
    mutations: {
        changeUserMainInfo (state, payload) {
            state.userMainInformation = Object.assign({}, payload);
            state.userInformationDone = true;
        },
        changeUserDateTime(state, payload){
            state.userDateTime = `${payload}`;
            state.userDateTimeDone = true
        }
    },
    actions: {
        asyncUserChangeInformation ({commit}, payload) {
            console.log('payload',payload);
            commit('changeUserMainInfo', payload);
        },
        asyncUserChangeDateTime ({commit}, payload) {
            console.log('payload2time',payload);
            commit('changeUserDateTime', payload);
        }
    },
    getters: {
        userInformationDone(state) {
            return state.userInformationDone
        },
        computedUserInformation (state) {
            return state.userMainInformation
        },
        userDateTime(state) {
            return state.userDateTime
        },
        userDateTimeDone(state) {
            return state.userDateTimeDone
        }
    }
}
