<template>
  <div>
    <template v-if="!startAvailabilityDate">
      <div class="container">
        <div class="row"></div>
        <div class="row">
          <div class="progress col s12">
            <div class="indeterminate"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="startAvailabilityDate">
      <form ref="secondForm" class="col s12 form-secondary"  @submit.prevent="submitHandlerData">
        <div class="row"></div>
        <div class="row">
          <div class="col s12">
            <h6 class="center">Appointment Date and Time</h6>
          </div>
          <div class="input-field col s12">
            <input
                name="date"
                type="text"
                class="datepicker"
                v-model.lazy="selectDate" :class="{invalid : $v.selectDate.$dirty && !$v.selectDate.required}">
            <label for="date">Date</label>
            <small
                v-if="$v.selectDate.$dirty && !$v.selectDate.required"
                class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>
          </div>
          <div v-if="time" class="input-field col s12">
            <template v-if="time.length">
              <select v-model.lazy="selectedOption" @change="logSelectedOption" name="time" :class="{invalid : $v.selectedOption.$dirty && !$v.selectedOption.required}">
                <option value="" disabled selected>Choose your option</option>
                <option v-for="t in time" :key="t.time" :value=t.time>{{ new Date(t.time).toLocaleTimeString()}}</option>
              </select>

              <label for="time">Time</label>

              <small
                  v-if="$v.selectedOption.$dirty && !$v.selectedOption.required"
                  class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>
            </template>

            <template v-else>
              <span>no available time on this date, please choose another date</span>
            </template>
          </div>
        </div>

        <div class="row">
          <div class="col s12 right-align">
            <button class="btn waves-effect waves-light " type="submit" name="action">Next
              <i class="material-icons right">send</i>
            </button>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import availability from "@/api/availability";
import {email, minLength, required} from 'vuelidate/lib/validators'

export default {
  name: 'secondFormDateAndTime',
  data: () => ({
    selectDate: '',
    time: '',
    startAvailabilityDate: null,
    endAvailabilityDate: '',
    chosenDay: '',
    chosenTime: '',
    selectedOption: null,
  }),
  validations: {
    selectDate: {required},
    selectedOption: {required}
  },
  watch: {
    selectDate () {
      this.getTimesForCurrentDate(this.selectDate);
    }
  },
  methods: {
    logSelectedOption() {},
    async submitHandlerData() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      await this.$store.dispatch('asyncUserChangeDateTime', this.selectedOption);
    },

    getTimesForCurrentDate (currentDate) {
      this.time = null
      availability
          .getAvailabilityTimesByDay(currentDate)
          .then((data) => {
            console.log('data',data)
            this.time = data
            console.log( ' this.time' ,  this.time)

            setTimeout(()=>{
              var selectElems = document.querySelectorAll('select');
              M.FormSelect.init(selectElems, {});
            },0)
          })
          .catch((e) => {
            console.log(e)
          })
    }
  },

  async mounted () {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    var currentYM = yyyy + '-' + mm;

    await availability
        .getAvailabilityDates(currentYM)
        .then((data) => {
          console.log('data',data)
          this.startAvailabilityDate = data[0].date;
          this.endAvailabilityDate = data[data.length - 1].date
        })
        .catch((e) => {
          console.log(e)
        })

    setTimeout(()=>{
      var datePicker = document.querySelectorAll('.datepicker');
      M.Datepicker.init(datePicker, {
        format: "yyyy-mm-dd",
        minDate: new Date(this.startAvailabilityDate),
        maxDate: new Date(this.endAvailabilityDate)
      });
      M.updateTextFields();
    },0)
  },
}
</script>
