import axios from '@/api/axios'

const createAppointment = (d) => {
    return axios({
        method: "post",
        url: "tcp-appt2/acuity.php",
        data: {
            request: 'appointment_send',
            first_name: `${d.firstName}`,
            last_name: `${d.lastName}`,
            email: `${d.email}`,
            phone: `${d.phone}`,
            appointment_type: 44634017,
            availability_date: `${d.datetime}`,
            availability_time: `${d.datetime}`
        },
        headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            console.log(response);
        });
}

export default {
    createAppointment,
}
