<template>
  <div>
    <div class="row"></div>
    <div class="row row-stripe-form">
      <div class="col s12">
        <h6 class="center">Payment Information</h6>
      </div>
      <div class="col s12">
        <label>Card Number</label>
        <div class="input-field" id="card-number"></div>
        <label>Card Expiry</label>
        <div class="input-field" id="card-expiry"></div>
        <label>Card CVC</label>
        <div class="input-field" id="card-cvc"></div>
        <div id="card-error"></div>
        <button id="custom-button" class="btn waves-effect waves-light" @click="createToken">Confirm Appointment</button>
      </div>

      <div ref="loader-form" :class="{ showed: load }" class="loader-form">
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-gree-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div><div class="gap-patch">
            <div class="circle"></div>
          </div><div class="circle-clipper right">
            <div class="circle"></div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .row-stripe-form .input-field {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    padding: 7px 0px;
  }
</style>

<style scoped>
.row-stripe-form {
  position: relative;
}

.row-stripe-form .loader-form {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: -2;
  background-color: rgba(255,255,255, 0.5);
}

.row-stripe-form .loader-form.showed {
  display: flex;
  z-index: 3;
}

.row-stripe-form label {
  font-size: 12px;
}

.row-stripe-form input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
}

#custom-button {
  display: block;
  margin-left: auto;
}
</style>

<script>
import { Stripe } from "stripe";
import welkincloud from "@/api/welkincloud";
import appointment from "@/api/posts";

export default {
  name: "stripeForm",
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      load: false
    };
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
    userMainInformation () {
      return this.$store.getters.computedUserInformation
    },
    userDateTime () {
      return this.$store.getters.userDateTime
    }
  },
  async mounted () {
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async createToken () {
      this.load = true

      //// Appointment
      const optionsAppointment = {
        datetime: `${this.userDateTime}`,
        firstName: `${this.userMainInformation.firstName}`,
        lastName: `${this.userMainInformation.lastName}`,
        email: `${this.userMainInformation.email}`,
        phone: `${this.userMainInformation.phone}`
      }
      console.log('optionsAppointment',optionsAppointment);

      await appointment
          .createAppointment(optionsAppointment)
          .then((data) => {
            console.log('data appointment', data);
          })
          .catch((e) => {
            console.log(e)
          })


      let patientWelkinID = ''

      /// Welkin
      await welkincloud
          .createPatient(optionsAppointment)
          .then((data) => {
            patientWelkinID = data.id;
            console.log('new patient',data)
          })
          .catch((e) => {
            console.log(e)
          })



      /// Stripe
      const stripe = Stripe(`${process.env.VUE_APP_SECRET_STRIPE_SK}`);

      const self = this;
      async function updateCustomers () {
        const customersSearch = await stripe.customers.search({
          query: 'email:\''+ self.userMainInformation.email +'\'',
          limit: 100
        });

        if (customersSearch.data.length <= 0) {
          return false;
        }

        await Promise.all(customersSearch.data.map(async (item) => {
          const customerUpdate = await stripe.customers.update(
              item.id,
              {
                metadata: {
                  welikanPatientId: '',
                },
              }
          );
        }));
      }

      updateCustomers();

      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        document.getElementById('card-error').innerHTML = error.message;
        return;
      }

      const customer = await stripe.customers.create({
        name: `${this.userMainInformation.firstName + ' ' + this.userMainInformation.lastName}`,
        email: `${this.userMainInformation.email}`,
        phone: `${this.userMainInformation.phone}`,
        metadata: {
          welikanPatientId: `${patientWelkinID}`
        }
      });

      const customerSource = await stripe.customers.createSource(
          `${customer.id}`,
          {
            source: `${token.id}`,
          }
      );

      this.$router.push({name: 'thank-you'})
    },
  }
};
</script>
