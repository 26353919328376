<template>
  <form ref="mainForm" class="col s12 form-main" @submit.prevent="submitHandler">
    <div class="row"></div>
    <div class="row">
      <div class="col s12">
        <h6 class="center">Personal Details</h6>
      </div>
      <div class="input-field col s6">
        <input
            id="first_name"
            type="text"
            class="validate"
            v-model.trim="firstName"
            :class="{invalid: ($v.firstName.$dirty && !$v.firstName.required) || ($v.firstName.$dirty && !$v.firstName.minLength)}">
        <label for="first_name">First Name</label>
        <small
            v-if="$v.firstName.$dirty && !$v.firstName.required"
            class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>
        <small
            v-if="$v.firstName.$dirty && !$v.firstName.minLength"
            class="helper-text invalid">First Name so tiny, min length should be {{ $v.firstName.$params.minLength.min }} symbols . Now First Name is {{firstName.length}}</small>
      </div>
      <div class="input-field col s6">
        <input
            id="last_name"
            type="text"
            class="validate"
            v-model.trim="lastName"
            :class="{invalid: ($v.lastName.$dirty && !$v.lastName.required) || ($v.lastName.$dirty && !$v.lastName.minLength)}">
        <label for="last_name">Last Name</label>
        <small
            v-if="$v.lastName.$dirty && !$v.lastName.required"
            class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>
        <small
            v-if="$v.lastName.$dirty && !$v.lastName.minLength"
            class="helper-text invalid">Last Name so tiny, min length should be {{ $v.lastName.$params.minLength.min }} symbols . Now Last Name is {{lastName.length}}</small>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s12">
        <input
            id="email"
            type="email"
            class="validate"
            v-model.trim="email"
            :class="{invalid: $v.email.$error}">
        <label for="email">Email Address</label>
        <small
            v-if="$v.email.$dirty && !$v.email.required"
            class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>

        <small
            v-else-if="$v.email.$dirty && !$v.email.email"
            class="helper-text invalid">it is not email</small>
      </div>
    </div>
    <div class="row">
      <div class="input-field col s2">
          <select v-model.lazy="phoneCountryCode" id="phone_country_code" disabled :class="{invalid: $v.phoneCountryCode.$error}">
            <option value="+1" selected>+1</option>
          </select>
          <label>Country Code</label>
      </div>
      <div class="input-field col s2">
        <input
            id="phone_area_code"
            type="text"
            class="validate"
            v-model.trim="phoneAreaCode"
            :class="{invalid: $v.phoneAreaCode.$error}">
        <label for="phone_area_code">Area Code</label>
        <small
            v-if="$v.phoneAreaCode.$dirty && !$v.phoneAreaCode.required"
            class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>
        <small
            v-if="($v.phoneAreaCode.$dirty && !$v.phoneAreaCode.minLength) || ($v.phoneAreaCode.$dirty && !$v.phoneAreaCode.maxLength) "
            class="helper-text invalid">&#9888; must be 3 digits</small>

        <small
            v-if="($v.phoneAreaCode.$dirty && !$v.phoneAreaCode.numeric)"
            class="helper-text invalid">&#9888; Only digits</small>
      </div>
      <div class="input-field col s8">
        <input
            id="phone"
            type="tel"
            class="validate"
            v-model.trim="phone"
            :class="{invalid : $v.phone.$dirty && !$v.phone.required}">
        <label for="phone">Phone Number</label>
        <small
            v-if="$v.phone.$dirty && !$v.phone.required"
            class="helper-text invalid">&#9888; This field is required. Please provide the necessary information to proceed.</small>

        <small
            v-if="($v.phone.$dirty && !$v.phone.minLength) || ($v.phone.$dirty && !$v.phone.maxLength) "
            class="helper-text invalid">&#9888; must be 7 digits</small>

        <small
            v-if="($v.phone.$dirty && !$v.phone.numeric)"
            class="helper-text invalid">&#9888; Only digits</small>
      </div>
    </div>

    <div class="row">
      <div class="col s12 right-align">
        <button class="btn waves-effect waves-light " type="submit" name="action">Next
          <i class="material-icons right">send</i>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {email, required, minLength, maxLength, numeric} from 'vuelidate/lib/validators'

export default {
  name: 'formMainInfo',
  data: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    phoneCountryCode: '+1',
    phoneAreaCode: ''
  }),
  validations: {
    firstName: {required, minLength: minLength(3)},
    lastName: {required, minLength: minLength(3)},
    phone: {required,minLength:minLength(7), maxLength: maxLength(7), numeric},
    email: {email, required},
    phoneCountryCode: {required},
    phoneAreaCode: {required, minLength: minLength(3), maxLength: maxLength(3), numeric}
  },
  methods: {
    async submitHandler () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: `${this.phoneCountryCode}` + `${this.phoneAreaCode}` + `${this.phone}`,
        email: this.email
      }
      await this.$store.dispatch('asyncUserChangeInformation', formData);
    }
  },
  mounted() {
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {});
  }
}
</script>
