import axios from "axios";

const createPatient = (data) => {
        return axios({
            method: "post",
            url: "tcp-appt2/welkin.php",
            data: {
                request: 'create_patient',
                firstName: `${data.firstName}`,
                lastName: `${data.lastName}`,
                email: `${data.email}`,
                phone: `${data.phone}`,
            },
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            console.log(response);
        });
}

export default {
    createPatient
}
