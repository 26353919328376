import axios from '@/api/axios'

const getAvailabilityDates = (currentYM) => {
    return axios({
        method: "post",
        url: "tcp-appt2/acuity.php",
        data: {
            request: 'availability_dates',
            appointment_type: 44634017
        },
        headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            console.log(response);
        });
}

const getAvailabilityTimesByDay = (currentDate) => {
    return axios({
        method: "post",
        url: "tcp-appt2/acuity.php",
        data: {
            request: 'availability_times',
            appointment_type: 44634017,
            availability_date: `${currentDate}`
        },
        headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (response) {
            console.log(response);
        });
}

export default {
    getAvailabilityDates,
    getAvailabilityTimesByDay
}
